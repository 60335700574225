const projects = {
  data: [
    {
      name: "Todo list",
      url: "https://github.com/0SansNom/todoList/",
      description: "Premier coup d'oeil à Laravel. C'est un todolist.",
      languages: [
        {
          name: "Vue",
          iconifyClass: "logos:vue",
        },
        {
          name: "PHP",
          iconifyClass: "logos:php",
        },
        {
          name: "Laravel",
          iconifyClass: "logos:laravel",
        },
      ],
    },
    {
      name: "web Konnexion",
      url: "https://webkonection.vercel.app/",
      description:
        "Here is web konnexion web site without framework. It's hosting on Vercel ",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
    {
      name: "Asteroid",
      url: "https://github.com/0SansNom/asteroids/",
      description:
        "It's a pratical work that consist to use Java in order to build a game of asteroids against plans ",
      languages: [
        {
          name: "Java",
          iconifyClass: "logos:java",
        },
      ],
    },
    {
      name: "Simple Calculator",
      url: "https://caluclatrice.netlify.app/",
      description:
        "Ce une calculatrice simple permettant de faire que des opérations élementaires",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
    {
      name: "Web app with Angular",
      url: "https://github.com/0SansNom/angularpractice/",
      description:
        "C'est le resultat lors de mon premier projet avec angular. ",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "TypeScript",
          iconifyClass: "vscode-icons:file-type-typescript-official",
        },
        {
          name: "Angular",
          iconifyClass: "logos:angular-icon",
        },
      ],
    },
    {
      name: "Moussa's folio",
      url: "https://github.com/0SansNom/moussa",
      description: "Un simple portfolio utilisant bootstrap ",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "Bootstrap",
          iconifyClass: "logos:bootstrap",
        },
      ],
    },
    {
      name: "Dice Game",
      url: "https://github.com/0SansNom/GameDice/",
      description:
        "Un jeu de dé. De base, il a été implenté en Java.  Celui-ci est la version Kotlin",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "Kotlin",
          iconifyClass: "vscode-icons:file-type-kotlin",
        },
      ],
    },
    {
      name: "Blog",
      url: "https://github.com/0SansNom/blogLaravel/",
      description: "C'est un blog fait avec Laravel pour mieux le découvrir.",
      languages: [
        {
          name: "PHP",
          iconifyClass: "logos:php",
        },
        {
          name: "Laravel",
          iconifyClass: "logos:laravel",
        },
      ],
    },
  ],
};

export default projects;
