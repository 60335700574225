const skills = {
  data: [
    {
      title: "Tools",
      File: "File",
      skills: ["I use some tools to make my work more efficient"],
      softwareSkills: [
        {
          id: 1,
          skillName: "VS Code",
          iconClanssName: "vscode-icons:file-type-vscode",
          style: {
            color: "white",
          },
        },
        {
          id: 2,
          skillName: "Intellij",
          iconClanssName: "logos:intellij-idea",
          style: {
            color: "rgb(0, 0, 0)",
          },
        },
        {
          id: 4,
          skillName: "Git",
          iconClanssName: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
        /*{
          id: 5,
          skillName: "Linux",
          iconClanssName: "logos:linux-tux",
          style: {
            color: "#000000",
          },
        }, */
        {
          id: 6,
          skillName: "Microsoft",
          iconClanssName: "logos:microsoft-windows",
          style: {
            color: "#00A4EF",
          },
        },
      ],
    },
    {
      title: " Development",
      fileName: "FullStackImg",
      skills: [
        "Develop highly interactive User Interfaces and Backend for web applications",
      ],
      softwareSkills: [
        {
          id: 7,
          skillName: "HTML5",
          iconClanssName: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          id: 8,
          skillName: "CSS3",
          iconClanssName: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          id: 9,
          skillName: "JavaScript",
          iconClanssName: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          id: 10,
          skillName: "PHP",
          iconClanssName: "la:php",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#8993be",
          },
        },
        {
          id: 11,
          skillName: "Java",
          iconClanssName: "logos:java",
          style: {
            color: "#5382a1",
          },
        },
        {
          id: 12,
          skillName: "Python",
          iconClanssName: "logos:python",
          style: {
            color: "#3776AB",
          },
        },

        {
          id: 13,
          skillName: "MySQL",
          iconClanssName: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        /*{
            id: 14,
            skillName: "Wordpress",
            iconClanssName: "simple-icons:wordpress",
            style: {
              color: "#207297",
            },
          },*/
      ],
    },
    {
      title: "Frameworks",
      fileName: "FrameworkImg",
      skills: ["I use some frameworks to make my work more efficient"],
      softwareSkills: [
        {
          id: 15,
          skillName: "ReactJS",
          iconClanssName: "logos:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          id: 16,
          skillName: "NodeJS",
          iconClanssName: "logos:nodejs",
          style: {
            color: "#000000",
          },
        },
        {
          id: 17,
          skillName: "Bootstrap",
          iconClanssName: "simple-icons:bootstrap",
          style: {
            color: "#563d7c",
          },
        },
        {
          id: 18,
          skillName: "Laravel",
          iconClanssName: "simple-icons:laravel",
          style: {
            color: "#E94E32",
          },
        },
        {
          id: 19,
          skillName: "Vue js",
          iconClanssName: "simple-icons:vuejs",
          style: {
            color: "#339933",
          },
        },
        {
          id: 20,
          skillName: "Angular",
          iconClanssName: "simple-icons:angular",
          style: {
            color: "#E93332",
          },
        },
        {
          id: 21,
          skillName: "NPM",
          iconClanssName: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "Experience hosting and managing websites",
        "Experience with Continuous Integration",
      ],
      softwareSkills: [
        {
          id: 22,
          skillName: "Vercel",
          iconClanssName: "simple-icons:vercel",
          style: {
            color: "#000000",
          },
        },
        {
          id: 23,
          skillName: "Netlify",
          iconClanssName: "simple-icons:netlify",
          style: {
            color: "#38AFBB",
          },
        },
        {
          id: 24,
          skillName: "Heroku",
          iconClanssName: "simple-icons:heroku",
          style: {
            color: "#6863A6",
          },
        },
        {
          id: 25,
          skillName: "Firebase",
          iconClanssName: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        /* {
            id: 26,
            skillName: "Docker",
            iconClanssName: "simple-icons:docker",
            style: {
            color: "#1488C6",
            },
         },*/
        {
          id: 27,
          skillName: "GitHub Actions",
          iconClanssName: "simple-icons:githubactions",
          style: {
            color: "#5b77ef",
          },
        },
      ],
    },
  ],
};

export default skills;
