const certifications = {
  certifications: [
    {
      title: "AgileProject Manageùent",
      subtitle: "Scrum",
      logo_path: "scrum.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-e41d5ef8-627f-416d-be43-f745b8d089ba/",
      alt_name: "Paul Ashun",
    },

    {
      title: "Artificial Intelligence",
      subtitle: "Workplace",
      logo_path: "ai.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-b33a885d-f9ba-4982-a200-23e89f67eb43/",
      alt_name: "Artificial intelligence ",
    },

    {
      title: "AI Assistant",
      subtitle: "JARVIS with Python",
      logo_path: "ai1.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-3b17912c-7f36-4199-9efb-0439d3570f99/",
      alt_name: "AI assistance",
    },

    {
      title: "Web Hosting",
      subtitle: "Web Hosting and Domain Names",
      logo_path: "udemy.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-5b3a0c79-25d7-4800-9937-f25c659261eb/",
      alt_name: "Web Hosting",
    },

    {
      title: "Python",
      subtitle: "des fondamentaux aux concepts avancés du langage",
      logo_path: "funmooc.png",
      certificate_link:
        "https://lms.fun-mooc.fr/media/attestations/attestation_suivi_course-v1:UCA+107001+session02_d35bd1db5a5e28b38131f1dbee59d5c2.pdf",
      alt_name: "Python",
    },

    {
      title: "Wordpress ",
      subtitle: "Website Developer",
      logo_path: "wordpress.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-581f9579-6375-400e-b490-8482deb74ae7/",
      alt_name: "worpress",
    },
  ],
};

export default certifications;
