const degrees = {
  degrees: [
    {
      title: "Aix - Marseille University",
      subtitle: "Bachelors in Computer Science",
      logo_path: "AMU_Logo.png",
      alt_name: "SSEC",
      duration: "2019 - Now",
      descriptions: [
        "  I'm currently pursuing my Bachelors in Computer Science and Engineering.",
        " I have studied core subjects like Data Structures, DBMS, etc.",
        "I have also completed various online courses for Backend , Web  App Development, etc.",
        " I have implemented several projects based on what I've leart under my Computer Engineering course. ",
      ],
      website_link: "https://www.univ-amu.fr/",
    },

    {
      title: "Dakar Institute of Technology ",
      subtitle: "Bachelors in BIg Data",
      logo_path: "DIT_Logo.png",
      alt_name: "SSEC",
      duration: "2021 - Now",
      descriptions: [
        "  I'm currently pursuing my Bachelors in Big Data.",
        " I have studied core subjects like Data Structures, DBMS, etc.",
        // "⚡ I have also completed various online courses for Backend , Web , Mobile App Development, etc.",
        // "⚡ I have implemented several projects based on what I've leart under my Computer Engineering course. ",
      ],
      website_link: "https://www.dit.sn/",
    },
  ],
};

export default degrees;
