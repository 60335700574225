import { lazy, Suspense } from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import { settings } from "../data/portfolio.js";

const Home = lazy(() => import("../pages/home/HomeComponent"));
const Splash = lazy(() => import("../pages/splash/Splash"));
const Education = lazy(() => import("../pages/education/EducationComponent"));
const Experience = lazy(() => import("../pages/experience/Experience"));
const Contact = lazy(() => import("../pages/contact/ContactComponent"));
const Projects = lazy(() => import("../pages/projects/Projects"));

export default function Main(propss) {
  return (
    <div>
      <BrowserRouter basename="/">
        <Switch>
          <Route
            path="/"
            exact
            render={(props) => (
              <Suspense fallback={<div></div>}>
                <Splash
                  {...props}
                  theme={propss.theme}
                  setTheme={propss.setTheme}
                />
              </Suspense>
            )}
          />
          <Route
            path="/home"
            render={(props) => (
              <Suspense fallback={<div></div>}>
                <Home
                  {...props}
                  theme={propss.theme}
                  setTheme={propss.setTheme}
                />
              </Suspense>
            )}
          />
          <Route
            path="/experience"
            exact
            render={(props) => (
              <Suspense fallback={<div></div>}>
                <Experience
                  {...props}
                  theme={propss.theme}
                  setTheme={propss.setTheme}
                />
              </Suspense>
            )}
          />
          <Route
            path="/education"
            render={(props) => (
              <Suspense fallback={<div></div>}>
                <Education
                  {...props}
                  theme={propss.theme}
                  setTheme={propss.setTheme}
                />
              </Suspense>
            )}
          />
          <Route
            path="/contact"
            render={(props) => (
              <Suspense fallback={<div></div>}>
                <Contact
                  {...props}
                  theme={propss.theme}
                  setTheme={propss.setTheme}
                />
              </Suspense>
            )}
          />
          <Route
            path="/splash"
            render={(props) => (
              <Suspense fallback={<div></div>}>
                <Splash
                  {...props}
                  theme={propss.theme}
                  setTheme={propss.setTheme}
                />
              </Suspense>
            )}
          />
          <Route
            path="/projects"
            render={(props) => (
              <Suspense fallback={<div></div>}>
                <Projects
                  {...props}
                  theme={propss.theme}
                  setTheme={propss.setTheme}
                />
              </Suspense>
            )}
          />
          <Route
            path="https://akhamadou-blog.web.app/blog/"
            render={(props) => <Suspense fallback={<div></div>}></Suspense>}
          />
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    </div>
  );
}
