import React, { useState } from "react";
import "./App.css";
import Main from "./containers/Main";
import { ThemeProvider } from "styled-components";
import { themes } from "./theme";
import { GlobalStyles } from "./global";
import { CursorProvider } from "react-cursor-custom";
import { settings } from "./data/portfolio";

function App() {
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");
  const useCursor = settings.useCustomCursor;

  return (
    <ThemeProvider theme={themes[theme]}>
      <GlobalStyles />

      {useCursor ? (
        <CursorProvider
          color={themes[theme].secondaryText}
          ringSize={25}
          transitionTime={75}
        >
          <Main theme={themes[theme]} setTheme={setTheme} />
        </CursorProvider>
      ) : (
        <Main theme={themes[theme]} setTheme={setTheme} />
      )}
    </ThemeProvider>
  );
}

export default App;
