const greeting = {
  title: "Hi, my name is",
  title2: "Akhmadou.",
  logo_name: "{Ak.N}",
  // nickname: "ndiaye",
  full_name: "Akhmadou Ndiaye",
  subTitle:
    "I'm a student on Computer Science and Big Data too. I particpate on some open source projects. I am also CEO of TEKFI.",
  resumeLink:
    "https://docs.google.com/document/d/1OU06vtk5X5hrAXJJeO9w80Og7LrpQ2x7UeuZNlUUe9Q/edit?usp=sharing",
  mail: "mailto:akhmadoun8@gmail.com",
};

export default greeting;
