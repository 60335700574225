// Theme Presets

const lightTheme = {
  name: "light",
  body: "#fff",
  text: "#121c42",
  dark: "#22244d",
  secondaryText: "#000",
  accentColor: "#4869ee",
  accentBright: "#203eba",
  projectCard: "#DCE4F2",
  skinColor: "#d16723",
  skinColor2: "#FCB696",
  imageDark: "#f7fafb",
  imageClothes: "#292A2D",
  avatarMisc: "#212121",
  avatarShoes: "#2B2B2B",
};

const darkTheme = {
  name: "dark",
  body: "#171933",
  text: "#f0f3ff",
  dark: "#22244d",
  secondaryText: "#f0f3ff",
  accentColor: "#feb32b",
  accentBright: "#203eba",
  projectCard: "#22244d",
  skinColor: "#d16723",
  skinColor2: "#FCB696",
  imageDark: "#22244d",
  imageClothes: "#0000",
  avatarMisc: "#212121",
  avatarShoes: "#2B2B2B",
};

export const themes = { light: lightTheme, dark: darkTheme };
