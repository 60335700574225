const experience = {
  title: "Experience",
  subtitle: "Work, Internship, Entrepreneurship and Volunteership",
  description:
    "I've mostly done projects on my own and I am actively looking for internships. I love organizing workshops to share my knowledge with others.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work Experience",
      experiences: [
        {
          title: "Gestionnaire de Liquidation de Retraite",
          company: "C2I Production",
          company_url: "https://www.tessi.eu/fr/",
          logo_path: "TESSI_Logo.png",
          duration: "Aug 2021 - Oct 2021",
          location: "Presentiel",
          description: ` Analyser des dossiers de retraites pour le compte d'une caisse de retraite. Verifier les preuves fournies par le demendeur. Traiter une partie de dossier. utiliser plusisieurs applications et de bases de données. 
          `,
        },
        {
          title: "Secrétaire Administratif",
          company: "Le Royaume Des Meres",
          company_url: "https://www.joceane.fr/royaume-des-mers.html",
          logo_path: "RDM_Logo.jpg",
          duration: "Jun 2021 - Jul 2021",
          location: "Presentiel",
          description: `Saisir les commandes des clients. Faire le cheking des commandes sortants. S'accamoder aux tournés des chauffeurs.  
          `,
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Membre commision relation exterieur",
          company: "COUREL",
          company_url: "http://courel.renderforestsites.com/",
          logo_path: "COUREL_Logo.jpeg",
          duration: "Nov 2021 - Present",
          location: "Remote",
          description:
            "Coopérative des Oeuvres unies pour la réussite des etreprises locales  ",
        },
        {
          title: "Membre commision Information et Communication",
          company: "COUREL",
          company_url: "http://courel.renderforestsites.com/",
          logo_path: "COUREL_Logo.jpeg",
          duration: "Dec 2020 - Present",
          location: "SRM IST Kattankulathur",
          description:
            "Coopérative des Oeuvres unies pour la réussite des etreprises locales  ",
        },
      ],
    },
    {
      title: "Entrepreneurship",
      experiences: [
        {
          title: " CEO TEKFI",
          company: "entrepreneuriat",
          company_url: "https://tekfi.sn/",
          logo_path: "TEKFI_Logo.png",
          duration: "Jan 2020 - Present",
          location: "",
          description: "Une enreprise qui évolue dans le domaine du digital",
        },
      ],
    },
  ],
};

export default experience;
